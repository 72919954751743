<template>
  <div class="absolute inset-0 object-cover w-full h-full">
    <NuxtImg v-if="(!isDesktop || !isClient) && backgroundImageMobile?.length"
             :src="backgroundImageMobile"
             alt=""
             class="object-cover w-full h-full lg:hidden"
             sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
             preload
    />
    <video v-if="(!isDesktop || !isClient) && backgroundVideoMobile?.length"
           class="object-cover w-full h-full lg:hidden"
           playsinline
           loop
           autoplay
           muted
           disableremoteplayback="true"
    >
      <source :src="backgroundVideoMobile"
              :media="`(max-width: ${TABLET_BREAKPOINT}px)`"
      >
    </video>

    <NuxtImg v-if="(isDesktop || !isClient) && backgroundImage?.length"
             :src="backgroundImage"
             alt=""
             class="max-lg:hidden object-cover w-full h-full"
             sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
             preload
    />
    <video v-if="(isDesktop || !isClient) && backgroundVideo?.length"
           class="max-lg:hidden object-cover w-full h-full"
           playsinline
           loop
           autoplay
           muted
    >
      <source :src="backgroundVideo"
              :media="`(min-width: ${TABLET_BREAKPOINT}px)`"
      >
    </video>
  </div>
</template>

<script setup lang="ts">
import { TABLET_BREAKPOINT, useScreenSize } from '~/composables/use-screen-size'

interface HeroBackgroundProps {
  backgroundImage?: string
  backgroundImageMobile?: string
  backgroundVideo?: string
  backgroundVideoMobile?: string
}

withDefaults(defineProps<HeroBackgroundProps>(), {
  backgroundImage: '',
  backgroundImageMobile: '',
  backgroundVideo: '',
  backgroundVideoMobile: ''
})

// Checking if client, to conditionally render elements because:
//   - all elements with src should be prerendered
//   - unnecessary media should not be fetched
//   - video <sources>'s media attr only works on load which prevents a pure CSS
//     responsive solution when screen size changes after initial load
const isClient = import.meta.client
const { isDesktop } = useScreenSize()
</script>
